import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Productdetail() {
    const navigate = useNavigate();
    const { id, category } = useParams();
    const [imageData, setImageData] = useState([]);
    const [data1, setdata1] = useState([]);
    const [data2, setdata2] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let FormData = {
                    idd: id,
                    cat: category
                }

               

                const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage/id`, FormData);

                setImageData(imageDataResponse.data.data1);
                setdata1(imageDataResponse.data.data2);
                setdata2(imageDataResponse.data.data3);

            } catch (error) {
                console.error(error);
            } finally {
            }
        };

        fetchData();
    }, [category, id]);

    const handlepropertyselect = (id, category) => {
        navigate(`/products/${category}/${id}`);
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <div><Headers /> </div>


            <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end container">
                <ol class="breadcrumb px-4">
                    <li class="breadcrumb-item">
                        <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <a href="/products">Product</a>
                    </li>
                </ol>
            </nav>



            <div class=" container card-box-c  p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
            <button
                        className="mb-2 px-4  text-slate-900 rounded-md hover:bg-green-700  hover:text-white focus:outline-none focus:ring focus:ring-green-400"
                        onClick={() => {
                            // Implement a navigation action or go back to a specific page
                            window.history.back();
                            window.scrollTo(0, 0);
                        }}
                    >
                        Go Back
                    </button>
                <div className="row">
                    
                    <div className="col-md-4">
                        <div class="img-box-a">
                            <img src={require('./Component/photo/' + id + '.png')} alt="ImageData" title={imageData.Title} style={{ maxHeight: '400px', width: '400px' }} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div class="min-w-0 lg:col-span-3 md:mt-2 space-y-3">
                            <div>
                                <h1 class="!text-3xl !text-left">{imageData.Product_Line} - {imageData.Brand}</h1>
                                <div class="mt-2 flex items-center gap-2 text-sm text-gray-500 ">
                                    <span className="capitalize">{imageData.Type}</span> |<span>SKU# {imageData.SKU}</span></div></div><div>
                                <div class="mb-1 font-semibold text-sm text-gray-500">
                                    Color: <span class="font-normal text-black">{imageData.color}</span>
                                </div></div><div><div></div></div>
                            <div class="flex gap-2 flex-wrap !mt-8 p-3">
                                <a href='/free-estimate'> <div className='btn btn-success w-30'>Request more info</div> </a>

                            </div>
                            <ul class="flex flex-wrap gap-0.5 m-0 mt-2 p-0 list-none">
                                {data1.map((item) => (
                                    <li key={item.SKU_Id} >
                                        <button className="md:p-2 lg:p-2 p-1" onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                             <img src={require('./Component/photo/' + item.SKU + '.png')} alt="ImageData" title={item.color} style={{ height: '48px' }} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container p-5">
                <h3>
                    Specifications
                </h3>
                <table class="table table-striped">

                    <tbody>
                        {imageData.Brand && <tr>
                            <th scope="row" style={{ width: '10%' }}>Brand</th>
                            <td>{imageData.Brand}</td>
                        </tr>}
                        
                        {imageData.Product_Line && <tr>
                            <th scope="row" style={{ width: '10%' }}>Product Line</th>
                            <td>{imageData.Product_Line}</td>
                        </tr>}
                        {imageData.color && <tr>
                            <th scope="row" style={{ width: '10%' }}>Color</th>
                            <td>{imageData.color}</td>
                        </tr>}
                        {imageData.SKU && <tr>
                            <th scope="row" style={{ width: '10%' }}>SKU #</th>
                            <td>{imageData.SKU}</td>
                        </tr>}
                        {imageData.Category && <tr>
                            <th scope="row" style={{ width: '10%' }}>Category</th>
                            <td>{imageData.Category}</td>
                        </tr>}
                        {imageData.Width && <tr>
                            <th scope="row" style={{ width: '10%' }}>Width</th>
                            <td>{imageData.Width}</td>
                        </tr>}
                        {imageData.FiberType && <tr>
                            <th scope="row" style={{ width: '10%' }}>FiberType</th>
                            <td>{imageData.FiberType}</td>
                        </tr>}
                        {imageData.FaceWeight && <tr>
                            <th scope="row" style={{ width: '10%' }}>FaceWeight</th>
                            <td>{imageData.FaceWeight}</td>
                        </tr>}
                        {imageData.FiberBrand && <tr>
                            <th scope="row" style={{ width: '10%' }}>FiberBrand</th>
                            <td>{imageData.FiberBrand}</td>
                        </tr>}
                        {imageData.PatternRepeatWidth && <tr>
                            <th scope="row" style={{ width: '10%' }}>PatternRepeatWidth</th>
                            <td>{imageData.PatternRepeatWidth}</td>
                        </tr>}
                        {imageData.PatternRepeatLength && <tr>
                            <th scope="row" style={{ width: '10%' }}>PatternRepeatLength</th>
                            <td>{imageData.PatternRepeatLength}</td>
                        </tr>}
                        

                    </tbody>
                </table>
                <br></br>
                <h3>
                    Similar Products
                </h3>
                <div className="row mb-2 ">
                    {data2.map((item) => (
                        <div key={item.SKU} class=" col-md-3 p-2" id={item.Brand}>
                            <button onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                <div class="card-box-c  p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
                                    <div class="img-box-a">
                                         <img src={require('./Component/photo/' + item.SKU + '.png')} alt="ImageData" />
                                    </div>

                                    <div className="row" ><strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Brand} </strong> <br />
                                        <strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Product_Line}</strong><br></br>
                                        <strong class="flex items-center text-sm font-semibold text-gray-700 "> {item.color}</strong> </div>
                                    <div className='btn btn-success w-75 '>See More colors</div>
                                </div>

                            </button>



                        </div>

                    ))}
                </div>
            </div>








            <div><Request /> </div>
            <div><Footer /> </div>
        </div>
    )
}

export default Productdetail
