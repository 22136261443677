import React from 'react'
import home5 from './Component/product/hardware1.png';
import h2 from './Component/product/hardware2.png';
import hss from './Component/product/hss.png';
import Headers from './Component/Header';
import Footer from './Component/Footer';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
    
    <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home5})`}} data-stellar-background-ratio="0.5">
      <div className="overlay" style={{background:'rgba(0, 0, 0, 0.3)'}}>
      <div className="container">
        <div className="row no-gutters slider-text align-items-end justify-content-center">
          <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
            <h2 className=" "><div className='text-white  capitalize'>Contact Us</div></h2>
            <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

            <a href='https://www.pointy.com/shops/usa/pennsylvania/claysburg/carpet-depot-home-center'> <div className='btn btn-success '>View Our Section</div> </a>
          </div>
        </div>
      </div>
      </div>
      
    </section>
    <div className="container mx-auto mt-8">
      <p className="text-2xl font-bold mb-4">Do you need special building materials?</p>
      <p className="mb-4">
        Did you know we have over 6,000 square footage of hardware items? Building materials and hardware are beneficial to
        have on hand whether you’re doing a complete home remodel or only attending to a minor task around the house. The
        truth is, if you own a home, you’re going to need these specialty pieces at some point. It’s honestly better to
        have them and not need them than to need them and not have them, so you can’t go wrong by finding out more.
      </p>

      <p className="font-bold mb-2">Materials we offer:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>Building Materials - lumber, drywall, insulation</li>
        <li>Plumbing & Heating</li>
        <li>Electrical Supplies</li>
        <li>Paint and Painting Supplies</li>
      </ul>

      <p className="mb-4">
        <span className="font-bold">Why choose your building supplies?</span> With most remodels, flooring, paint, and more,
        you benefit from professional services that cater to your every need. This makes sense because many of these
        materials come with a warranty that can be voided if professional service people are not used. However, there are
        many things around the house that could benefit from the perfect supplies and a skilled or learned hand.
      </p>

      <p className="mb-4">
        Since this section is dedicated solely to materials that make life easier to navigate and manage, you won’t be
        surprised to find high-quality materials are waiting on you. However, you’ll find these materials incredibly
        affordable, even if purchasing large amounts for bigger jobs. You’ll also receive customer service from qualified
        individuals who can discuss the ins and outs of all the available materials, so you never rush ahead in the
        process without a groundwork on knowledge.
      </p>

      <p className="mb-4">
        Our associates aren't just skilled and experienced in one aspect of your flooring. They are well-versed in many
        different ways. They can easily answer questions about all the materials we carry, and you’ll find you have a
        better shopping experience because of that. When you’re ready to shop for excellent building materials, stop by
        anytime.
      </p>
    </div>
    <section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Our suppliers</span>
				</div>
				<div><img src={hss} alt="Los Angeles" class="d-block" /></div>

			</section>

    <section className='container'>
				<div class="row" style={{ paddingTop: '100px' }}>
					<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<img src={h2} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%',height:'350px' }} />
					</div>
					<div class="col-sm-12 col-md-8 col-lg-8 col-xl-8" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="lead font-bold">We supply excellent building materials</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo p-4" style={{ border: '2px solid #A9B6D3', borderRadius: '5px'}}>
										<div class="card-body-c align-self-center">
											<p class="content-c">When you need a hardware store in Claysburg, PA, be sure to visit Carpet Depot Home Center at your convenience. You’ll find that we offer a wide variety of materials in our more than 5,000 square feet of showroom space and have an area specifically dedicated to automotive supplies and hardware. We also supply a full line of gardening tools and supplies. No matter what types of supplies you need, you’re sure to find them here.</p>

											<p class="content-c">
                                            Our associates are always ready to help with your services and lend a helping hand. They also are highly knowledgeable, offering answers to all your most important questions. If you’re a resident in Claysburg, PA, Duncansville, PA, Pleasantville, PA, Roaring Spring, PA, or other parts of Blair County, PA, Bedford County, PA, or Cambria County, PA, and you're ready for a great hardware store experience for your building materials, be sure to stop by.
											</p>

										</div>
									</div>
								</div>

							</div>
							<br></br>
							<p class="lead">
								<a href="/free-estimate" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }}>Request an Estimate</a>
							</p>        </div>

					</div>
				</div>
			</section >
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
