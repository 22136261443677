import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import f2 from './Component/f2.png';
import f4 from './Component/f4.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4'>
        <span style={{ fontSize: '35px' }}>Financing</span> <br />
        <small className='justify'>CALL US OR STOP IN TODAY TO EXPLORE ALL OF OUR AVAILABLE FINANCING OPTIONS</small>
      </div>
      <section className='container'>
        <div class="row p-2" >
          
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
            paddingInline: '5% 10%'
          }}>
            <div >


              <div className='row d-flex justify-content-center'>
                <div class="col-md-12">
                  <div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
                    <div class="card-body-c align-self-center">
                      <div className='d-flex justify-content-center'> <img src={f4} alt="Los Angeles" class="d-block " />
                      </div>
                      <div className='d-flex justify-content-center p-4'>  <img src={f2} alt="Los Angeles" style={{height:'65px',width:'205px'}}/>
                      </div>
                     
                      <p class="content-c">Whether it’s the perfect sale or simply the perfect timing for your home, you should be able to purchase your dream flooring when you want to.</p>

                      <p class="content-c">
                        With the Mohawk Credit Card, we aim to provide you with the financial management tool to get your household exactly what you want when you want it. Now you can keep your cash and general credit cards freed up for family needs and emergencies.
                      </p>
                    </div>
                    <p class="lead">
                      <a href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=0A96374331BD708109B284BEDF45A5396F5E0E04435551D3&client=Mohawk%20Carpet&intcmp=mohawk-flooring-internalapply" target="_blank" rel="noreferrer" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} >Apply Now</a>
                    </p>
                  </div>
                </div>

              </div>
              <br></br>
            </div>

          </div>
        </div>
      </section >
      <div><Footer /> </div>
    </div>
  )
}

export default About
