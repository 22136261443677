import React, { useState, useEffect } from "react";
import Headers from './Component/Header';
import Footer from './Component/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router-dom";
import home5 from './Component/productheader.jpg';


function Glasstile() {
    const { id } = useParams();
    const [imageData, setImageData] = useState([]);
    const [uniquebrand, setuniquebrand] = useState([]);
    const [uniquewidth, setuniquewidth] = useState([]);
    const [uniqueFiberType, setuniqueFiberType] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = imageData.slice(startIndex, endIndex);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const formdata = {
                    idd: id
                }
                const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage`, formdata);
                setImageData(imageDataResponse.data.data1);
                setuniquebrand(imageDataResponse.data.data2);
                setuniquewidth(imageDataResponse.data.data3);
                setuniqueFiberType(imageDataResponse.data.data4);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [id]);

    // Helper function to convert base64 string to data URL
    //const base64ToDataUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;

    const handlepropertyselect = (id, category) => {
        navigate(`/products/${category}/${id}`);
        window.scrollTo(0, 0);
    }

    const totalPages = Math.ceil(imageData.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage > 0 ? currentPage - 1 : 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderPaginationButtons = () => {
        const buttons = [];
        for (let i = currentPage; i < totalPages && i < currentPage+4 ; i++) {
            buttons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''} p-2` } >
                    <button
                        type="button"
                        className={`btn ${currentPage === i ? 'btn-primary' : 'btn-secondary'}`}
                        onClick={() => handlePageClick(i)}
                    >
                        {i + 1}
                    </button>
                </li>
            );
        }
        return buttons;
    };
    const [formData, setFormData] = useState({
        Types: '',
        brand: '',
        width: null,
        FiberType: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(formData);
        try {
            const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage/similarimage`, formData);
            setImageData(imageDataResponse.data.data1);
            setuniquebrand(imageDataResponse.data.data2);
            setuniquewidth(imageDataResponse.data.data3);
            setuniqueFiberType(imageDataResponse.data.data4);
            onCloseMenu();
        } catch (error) {
            console.error(error);
        }

    };
    const handleReset = () => {
        setFormData({
            Types: '',
            brand: '',
            width: null,
            FiberType: null,
        });
    };
    const [menuOpen, setMenuOpen] = useState(false);

    const onToggleMenu = () => {
        setMenuOpen(true);
    };
    const onCloseMenu = () => {
        setMenuOpen(false); // Close the menu
    };
  const [visibleItems, setVisibleItems] = useState(4);
  const showMoreItems = () => {
    setVisibleItems(visibleItems + 4);
  };
    return (
        <div >
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home5})`}} data-stellar-background-ratio="0.5">
      <div className="overlay" style={{background:'rgba(0, 0, 0, 0.3)'}}>
      <div className="container">
        <div className="row no-gutters slider-text align-items-end justify-content-center">
          <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
            <h2 className=" "><div className='text-white  capitalize'>{id}</div></h2>
            <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

            <a href='# '> <div className='btn btn-success '>View Our Section</div> </a>
          </div>
        </div>
      </div>
      </div>
      
    </section>
            <div className=" container p-5 " >
                <div className="row ">
                    <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3    " >
                    <div class="flex items-center lg:order-2">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>
                    <div
						className={`nav-links duration-500 md:static absolute rounded  w-full  left-0 ${menuOpen ? 'top-[20%]' : 'top-[-500%]'
							} md:w-auto w-full flex items-center `} style={{backgroundColor:'#D3D3D3'}}
					>



                        <div className="  flex md:flex-row flex-col md:items-center rounded md:justify-content-center p-2 border-y-2 w-full border-white" style={{backgroundColor:'#D3D3D3'}}>
                            <form className="max-w-sm mx-auto px-3" onSubmit={handleSubmit}>
                                {/* Types field */}
                                <fieldset>
                                    <legend>Types</legend>
                                    {/* Carpet */}
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="carpet"
                                            name="Types"
                                            value="carpet"
                                            onChange={handleChange}
                                            checked={formData.Types === 'carpet'}
                                            required
                                        />
                                        <label htmlFor="carpet" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Carpet (4889)
                                        </label>
                                    </div>
                                    {/* Glass Tile */}
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="glasstile"
                                            name="Types"
                                            value="glasstile"
                                            onChange={handleChange}
                                            checked={formData.Types === 'glasstile'}
                                            required
                                        />
                                        <label htmlFor="glasstile" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Glass Tile (19)
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="laminate"
                                            name="Types"
                                            value="laminate"
                                            onChange={handleChange}
                                            checked={formData.Types === 'laminate'}
                                            required
                                        />
                                        <label htmlFor="laminate" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Laminate (48)
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="hardwood"
                                            name="Types"
                                            value="hardwood"
                                            onChange={handleChange}
                                            checked={formData.Types === 'hardwood'}
                                            required
                                        />
                                        <label htmlFor="hardwood" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Hardwood (114)
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="Tile"
                                            name="Types"
                                            value="Tile"
                                            onChange={handleChange}
                                            checked={formData.Types === 'Tile'}
                                            required
                                        />
                                        <label htmlFor="Tile" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Tile (607)
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="luxuryvinyl"
                                            name="Types"
                                            value="luxuryvinyl"
                                            onChange={handleChange}
                                            checked={formData.Types === 'luxuryvinyl'}
                                            required
                                        />
                                        <label htmlFor="luxuryvinyl" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                            Luxury Vinyl (278)
                                        </label>
                                    </div>
                                    <div className="flex items-center mb-4 px-3">
                                        <input
                                            type="radio"
                                            id="Resilient"
                                            name="Types"
                                            value="Resilient"
                                            onChange={handleChange}
                                            checked={formData.Types === 'Resilient'}
                                            required
                                        />
                                        <label htmlFor="Resilient" className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                          Resilient 
                                        </label>
                                    </div>
                                </fieldset>

                                {/* Brands field */}
                                <fieldset>
                                    <legend>Brands</legend>
                                    {/* Iterate over unique brands */}
                                    {uniquebrand.map((item) => (
                                        <div className="flex items-center mb-2 px-3" key={item.Brand}>
                                            <input
                                                type="radio"
                                                id={item.Brand}
                                                name="brand"
                                                value={item.Brand}
                                                onChange={handleChange}
                                                checked={formData.brand === item.Brand}
                                                required
                                            />
                                            <label htmlFor={item.Brand} className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                                {item.Brand}
                                            </label>
                                        </div>
                                    ))}
                                </fieldset>

                                {/* Width field */}
                                <fieldset>
                                    <legend>Width</legend>
                                    {/* Iterate over unique widths */}
                                    {uniquewidth.map((item) => (
                                        (item.Width && (
                                            <div className="flex items-center mb-2 px-3" key={item.Width}>
                                                <input
                                                    type="radio"
                                                    id={item.Width}
                                                    name="width"
                                                    value={item.Width}
                                                    onChange={handleChange}
                                                    checked={formData.width === item.Width}
                                                />
                                                <label htmlFor={item.Width} className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                                    {item.Width}
                                                </label>
                                            </div>
                                        ))
                                    ))}
                                </fieldset>

                                {/* Fiber Type field */}
                                <fieldset>
                                    <legend>Fiber Type</legend>
                                    {/* Iterate over unique fiber types */}
                                    {uniqueFiberType.slice(0, visibleItems).map((item) => (
        item.FiberType && (
          <div className="flex items-center mb-2 px-3" key={item.FiberType}>
            <input
              type="radio"
              id={item.FiberType}
              name="FiberType"
              value={item.FiberType}
              onChange={handleChange}
              checked={formData.FiberType === item.FiberType}
            />
            <label htmlFor={item.FiberType} className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-900">
              {item.FiberType}
            </label>
          </div>
        )
      ))}

      {visibleItems < uniqueFiberType.length ? (
        <button onClick={showMoreItems} className="text-blue-500 hover:underline cursor-pointer">
          More Items
        </button>
      ) : null}
                                </fieldset>

                                {/* Submit button */}
                                <div className="flex justify-between">
                                <button type="button" className="btn btn-secondary" onClick={handleReset}>
                                        Reset
                                    </button>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    
                                </div>
                            </form>
                            <br></br>
                            <div className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class=" font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
									Close
								</button>
							</div>
                        </div>
										
								
							
							


					</div>
                        
                    </div>
                    <div class="col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p class="mb-2 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-900" > Flooring from Carpet Depot Home Center in Claysburg, PA</p>

                        <div className="row mb-2 ">
                            {pageData.map((item) => (
                                <div key={item.SKU} class=" col-md-4 p-3" id={item.Brand}>
                                    <button onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                        <div class="card-box-c  p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
                                            <div class="img-box-a">
                                                {/* {item.encoded_data && <img src={base64ToDataUrl(item.encoded_data)} alt="ImageData" />} */}
                                                <img src={require('./Component/photo/' + item.SKU + '.png')} alt="Los Angeles" class="d-block img-fluid" title={item.Title} onError={(e) => {
                                                    e.target.src = './Component/photo/07044_SW676.png'; // Set the path to your default image
                                                }} />
                                            </div>

                                            <div className="row" ><strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Brand} </strong> <br />
                                                <strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Product_Line}</strong><br></br>
                                                <strong class="flex items-center text-sm font-semibold text-gray-700 "> {item.color}</strong> </div>
                                            {/* <span className="bg-green-700 rounded p-2" style={{ textAlign: 'right' }}></span> */}
                                            <br />
                                            <div className='btn btn-success w-75 '>See More colors</div>
                                        </div>

                                    </button>



                                </div>

                            ))}
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <nav className="pagination-a">
                                    <ul className="pagination justify-content-center flex">
                                        <li className="page-item p-2" >
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={handlePrevPage}
                                            >
                                                &laquo; Previous
                                            </button>
                                        </li>
                                        {renderPaginationButtons()}
                                        <li className="page-item p-2" >
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={handleNextPage}
                                            >
                                                Next &raquo;
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <div><Footer /> </div>
        </div >
    )
}

export default Glasstile
