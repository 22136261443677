import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import special1 from './Component/special1.jpg';
import flag from './Component/flag.png';

function Special() {
  return (
    <div>
    <div><Headers/></div>
    <h1 className='text-center p-4'>Special</h1>
    {/* <div className='d-flex justify-content-center'>
      <img src={special1} alt="Special" className="rounded" style={{ height: '600px' }} />
      <img src={carpetnew} alt="Special" className="rounded" style={{ height: '600px' }} />
    </div> */}
      <div class="row flex justify-center items-center ">
        <div class="col-md-6 col-lg-6 col-12 p-2 flex justify-center items-center justify-center bg-white " style={{ height: '100%' }}>
          <img src={special1}alt="Special" className="rounded" style={{ height: '600px' }}  />
        </div>

      </div>
      <div class=" container col-12 p-2 flex justify-center items-center justify-center bg-white " style={{ height: '100%' }}>
          <img src={flag} alt="Special" className="rounded"  />
        </div>
    <div><Footer/></div>
  </div>
  )
}

export default Special
