import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/s2.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT CARPET DEPOT HOME CENTER</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
                12756 Dunnings Highway Claysburg, PA 16625
              </p>
              <a href="https://www.google.com/maps/place/Carpet+Depot+Home+Center/@40.2797055,-78.4602664,17z/data=!4m13!1m7!3m6!1s0x89cbbb40ac9d8a09:0x4ad0a093de1ad51b!2s12756+Dunnings+Hwy,+Claysburg,+PA+16625!3b1!8m2!3d40.2797055!4d-78.4580777!3m4!1s0x89cbbb5dad8be211:0x7f325071cef55ee1!8m2!3d40.2797055!4d-78.4580777" target="_blank" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(814) 239-5865</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-600">Call us</p>
              <a href="tel:+18142395865" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
                Mon-Fri 8am - 5pm <br />
                Sat 9am - 2pm <br />
                Sun Closed
              </p>
            </div>

            <div>
              <p className="text-gray-600">Request an estimate</p>
              <a href="/free-estimate" class="btn btn-primary btn-lg active d-flex justify-content-center" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }} >Request an estimate</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5">
        <a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3043.8219375541794!2d-78.4602664!3d40.2797055!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89cbbb5dad8be211%3A0x7f325071cef55ee1!2sCarpet%20Depot%20Home%20Center!5e0!3m2!1sen!2sin!4v1700026114828!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
