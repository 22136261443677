import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home4 from './Component/home4.png';
import in1 from './Component/in1.png';
import in2 from './Component/in2.png';
import in3 from './Component/in3.png';
import in4 from './Component/in4.png';
function Inspiration() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>Reimagine your space</span> <br />
          <small className='justify'>GET INSPIRED WITH OUR BEAUTIFUL FLOORING GALLERIES</small>
        </div>
        <section className='row '>
          <div className='col-md-4 d-flex justify-content-center p-1 row'>
             <img src={in1} alt="Los Angeles" class="d-block" /> 
             <a href='/inspiration' className='d-flex justify-content-center text-green-800 font-bold'>Carpet Gallery  </a>
             </div>
          <div className='col-md-4 d-flex justify-content-center p-1 row'> <img src={in2} alt="Los Angeles" class="d-block" /> <div> <a href='/inspiration' className='d-flex justify-content-center text-green-800 font-bold'>Hardwood Gallery  </a></div></div>
          <div className='col-md-4 d-flex justify-content-center p-1 row'> <img src={in3} alt="Los Angeles" class="d-block" /> <div> <a href='/inspiration' className='d-flex justify-content-center text-green-800 font-bold'>Laminate Gallery  </a></div></div>
          <div className='col-md-4 d-flex justify-content-center p-1 row'> </div>
          <div className='col-md-4 d-flex justify-content-center p-1 row'> <img src={in4} alt="Los Angeles" class="d-block" /> <div> <a href='/inspiration' className='d-flex justify-content-center text-green-800 font-bold'>Luxury Vinyl Gallery  </a></div></div>
        </section >
      </div>

      <div><Request /></div>
     
      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <div><img src={home4} alt="Los Angeles" class="d-block" /></div>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default Inspiration
