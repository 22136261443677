import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import myImage from './Component/google-review.png';
function Reviews() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold '>
          <span style={{ fontSize: '35px' }}>Reviews</span> <br />
          <small className='justify'> HOW DID WE DO? LEAVE US A REVIEW BELOW!</small>
          <div class="flex items-center justify-center ">
            <a href="https://store.salescaptain.com/c5b67969-5bec-4500-ac29-1020341d065f/profile" target="_blank" rel="noreferrer">
              <img src={myImage} alt="Los Angeles" />
            </a>
          </div>
        </div>

        <section >
          <div>



          </div>
        </section >
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Reviews
