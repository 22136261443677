import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home4 from './Component/home4.png';
import about from './Component/about.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container p-4'>
        <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>About Carpet Depot Home Center</span> <br />
          <small className='justify'>GET TO KNOW YOUR CLAYSBURG, PA AREA FLOORING EXPERTS</small>
        </div>
        <section className='container'>
				<div class="row" style={{ paddingTop: '100px' }}>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
						<img src={about} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
						paddingTop: '30px ', paddingLeft: '80px', paddingRight: '80px', paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="lead font-bold">Serving you and your home since 1983!</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c">
                      For more than 30 years, the Burket Family has provided quality flooring as a Mohawk ColorCenter, furniture, hardware, and home improvement products to the Claysburg, Pennsylvania area. Carpet Depot Home Center offers its customers the best customer service, selection, and pricing. Serving a Tri County area, including Blair Country, Bedford Country, Cambria County.											</p>
                      <p class="lead">
								<a href="/contact" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }}>Contact Us</a>
							</p>  
										</div>
									</div>
								</div>

							</div>
							<br></br>
							      </div>

					</div>
				</div>
			</section >
      </div>
      <section class="p-5 bg-orange-300">
        <div class="row">
          <div class="col-md-3 ">

          </div>
          <div class="col-md-6 b">
            <div class="card-box-c foo p-5 bg-black text-white" style={{ borderRadius: '15px' }}>
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span ><i class="fa-sharp fa fa-house"></i></span>
                </div>
                <div class="card-title-c">
                  <h2 class=" text-center"> Serving you and your home since 1983!</h2>
                </div>
              </div>
              <div class="card-body-c align-self-center">
                <p class="content-c p-2">
                  Check out our catalog
                </p>
                <a href='/products'> <div className='btn btn-success w-100'>Browse Our Catalog</div> </a>
              </div>
            </div>

          </div>


        </div>
      </section>
      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <div><img src={home4} alt="Los Angeles" class="d-block" /></div>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
