import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import billboard from './Component/billboard.jpg';
import home2 from './Component/home2.png';
import home3 from './Component/home3.png';
import mainhome from './Component/mainhome.png';
import home4 from './Component/home4.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import carpetnew from './Component/carpetnew.png'
import SimpleModal from './simpleModal';
import flag from './Component/flag.png';
import CustomAd from './Component/customAd.png';

function Home() {
	// const t = "LM12";
	const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const hasClosedImage = localStorage.getItem('hasClosedImage');
    if (hasClosedImage === 'true') {
      setShowImage(false);
    }
  }, []);
  useEffect(() => {
	if(showImage === false) {
	setShowImage(false);
    localStorage.setItem('hasClosedImage', 'true');
	}
  },[showImage])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImage(false);
      localStorage.setItem('hasClosedImage', 'true');
    }, 3600000);

    return () => clearTimeout(timeout);
  }, []);
	return (
		<div >
			{/* <SimpleModal setOpen={setShowImage} open={showImage}>
				<div className="flex items-center justify-center sm:h-[90vh]">
					<img src={carpetnew} alt="popup " className='h-[100%]'/>
				</div>
			</SimpleModal> */}
			<Headers />
			{/* <div> <a href='/contact'> <img src= {require('./Component/photo/' + t + '.png')} alt="Los Angeles" class="d-block img-fluid" /></a></div> */}
			

			<section >
				<div className="hero-wrap hero-wrap-2" >
						<div className="static h-full" style={{ backgroundImage: `url(${billboard})` }} data-stellar-background-ratio="0.5">
							<div className=" inset-0 flex px-5 py-2 "style={{background:'rgba(0, 0, 0, 0.3)'}}>
								<div className="text-white text-center">
									<img
										src={mainhome}
										alt="Flooring, furniture and hardware needs"
										className="mx-auto "
									/>
									<p className="mt-4 text-xl">
										Explore our range of flooring, furniture, and hardware products.
									</p>
									<a href='/contact'> <div className='btn btn-success w-75'> Learn more</div> </a>
								</div>
							</div>
						
					</div>
				</div>

			</section>
			
			

			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">Looking for new floors in the Claysburg, PA area?</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400"> SHOP OUR EXTENSIVE FLOORING CATALOG</p>
					<div class="row flex justify-content-center ">
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white "style={{height: '100%'  }}>
							<img src={carpet} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/carpet'> <div className='btn btn-success w-100'> Carpet</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white "style={{height: '100%'  }}>
							<img src={hardwood} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/hardwood'> <div className='btn btn-success w-100'>Hardwood</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white "style={{height: '100%'  }}>
							<img src={laminate} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/laminate'> <div className='btn btn-success w-100'>Laminate</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white "style={{height: '100%'  }}>
							<img src={Tile} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/Tile'> <div className='btn btn-success w-100'> Tile</div> </a>
						</div>

					</div>
				</div>



			</section>

			<section>
				<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						
						<div class="carousel-item active" data-bs-interval="3000">
							<img
								src={CustomAd}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto"
							/>
						</div>
						<div class="carousel-item" data-bs-interval="3000">
							<img
								src={flag}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto"
							/>
						</div>
					</div>
					<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
						<span class="carousel-control-prev-icon bg-slate-300" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
						<span class="carousel-control-next-icon bg-slate-300" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</div>
				
			</section>

			<section class="p-4 bg-orange-300">
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-black text-white" style={{ borderRadius: '15px',height: '100%'  }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c ">
										The Claysburg, PA area trusts Carpet Depot Home Center for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-success w-100'> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-black text-white" style={{ borderRadius: '15px',height: '100%'  }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Request</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c lg:px-5 ">
										Request your accurate and professional in-home flooring estimate.
									</p>
									<div class="mt-auto">
            <a href='/free-estimate' class='btn btn-success w-100'>Schedule Now</a>
          </div>								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-black text-white" style={{ borderRadius: '15px',height: '100%'  }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Services</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c ">
										Our professional flooring installers are ready to help you with your next project.
									</p>
									<a href='/flooring-services'> <div className='btn btn-success w-100'> what we Offer</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-black text-white" style={{ borderRadius: '15px',height: '100%'  }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Financing</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c ">
										Get your dream floors now, pay over time with our convenient financing options.
									</p>
									<a href='/financing'> <div className='btn btn-success w-100'>Learn more</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>

			<section className='container'>
				<div class="row p-3" >
					
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="lead font-bold">Serving you and your home since 1983!</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c">For more than 30 years, the Burket Family has provided quality flooring as a Mohawk ColorCenter, furniture, hardware, and home improvement products to the Claysburg, Pennsylvania area. Carpet Depot Home Center offers its customers the best customer service, selection, and pricing. Serving a Tri County area, including Blair Country, Bedford Country, Cambria County.</p>
											<p class="lead">
								<a href="/about-us" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '8px' }}>Explore More About Us</a>
							</p>  

										</div>
									</div>
								</div>

							</div>
							      </div>

					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home2} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
					</div>
				</div>
			</section >
			<section className='container'>
				<div class="row p-3" >
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home3} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6  items-center justify-center " >
						<div className='jumbotron' >
							<p class="lead font-bold">Why should you choose us?</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c"><ul>
												<li>Over 30 years of experience</li>
												<li>Knowledgeable staff</li>
												<li>Commitment to excellence</li>
												<li>Relationships with suppliers</li>
												<li>Highest quality service</li>
											</ul></p>
											<p class="lead">
								<a href="/free-estimate" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '8px' }}>Request an Estimate</a>
							</p>  
										</div>
									</div>
								</div>

							</div>
							      </div>

					</div>
					

				</div>
			</section >
			<section className='container p-5'>
				<div class="row rounded">
					<div class="col-lg-4 col-md-12 mb-4 mb-lg-0 bg-black text-white rounded">
						<p className='text-center ' style={{ paddingTop: '150px' }}> <h1>Get inspired</h1>  <br /><a href="/inspiration" className='btn btn-success w-full p-2'> View our Galleries</a></p>

					</div>

					<div class="col-lg-8 mb-8 mb-lg-0">
						<img src={i1} alt="Los Angeles" class="d-block rounded" />


					</div>


				</div>
			</section>
			<div><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
				</div>
				<div><img src={home4} alt="Los Angeles" class="d-block" /></div>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
