import React from 'react'
import home5 from './Component/productheader.jpg';
import Headers from './Component/Header';
import Footer from './Component/Footer';
function Hardware() {
  return (
    <div>
        <div><Headers /></div>
        <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home5})`}} data-stellar-background-ratio="0.5">
      <div className="overlay" style={{background:'rgba(0, 0, 0, 0.3)'}}>
      <div className="container">
        <div className="row no-gutters slider-text align-items-end justify-content-center">
          <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
            <h2 className=" "><div className='text-white  capitalize'>Furniture</div></h2>
            <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

            <a href='/free-estimate'> <div className='btn btn-success '>Request an Estimate</div> </a>
          </div>
        </div>
      </div>
      </div>
      
    </section>
    <div className="container mx-auto mt-8">
      <p className="text-2xl font-bold mb-4">Be careful to choose the perfect furniture</p>
      <p className="mb-4">
      There’s no doubt that products like flooring, wall colors, and lighting have a lot to do with your overall décor success, but furniture could be what creates the perfection for which you’ve been waiting. Consider the wide variety of options available for visuals, longevity, and functionality, especially in spaces where it will be put to use frequently. Welcoming areas such as living rooms see this type of traffic, as do others, and it truly makes a difference when you choose quality materials right from the start.
      </p>

      <p className="font-bold mb-2">Our furniture favorites:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><a href='http://www.besthf.com/' target="_blank" rel="noreferrer"> <div className='text-green-800 font-bold'>Best Home Furnishings</div> </a></li>
        <li><a href='https://www.coasterfurniture.com/' target="_blank" rel="noreferrer"> <div className='text-green-800 font-bold'>Coaster Furniture</div> </a></li>
        <li><a href='https://www.lancerfurniture.com/' target="_blank" rel="noreferrer"> <div className='text-green-800 font-bold'>Lancer Furniture</div> </a></li>
        <li><a href='https://www.brazilfurnitureusa.com/photos' target="_blank" rel="noreferrer"> <div className='text-green-800 font-bold'>Brazil Furniture</div> </a></li>
        <li><a href='https://www.teioak.com/' target="_blank" rel="noreferrer"> <div className='text-green-800 font-bold'>Tennessee Enterprises, Inc.</div> </a></li>
      </ul>

      <p className="mb-4">One of the most highly sought-after materials for these products is solid wood. Not only does it match well with nearly every décor, but it’s also easier to clean than you might think. They do not show stains easily, and a simple dusting can keep them looking their best.
<br></br><br></br>
Don’t forget to consider pieces for your outdoor spaces as well. Plastic is an excellent material for these spaces, as it is affordable and very easy to maintain. Metal and wood are also feasible options, with the wood products receiving a water repellant treatment to help it stand up in various weather conditions. If you’d like more information, be sure to visit us when you’re in the area.
      </p>

     
    </div>
    

    <section className='container'>
				<div class="row" style={{ paddingTop: '100px' }}>
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="lead font-bold">We have the furniture you need</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo p-4" style={{ border: '2px solid #A9B6D3', borderRadius: '5px'}}>
										<div class="card-body-c align-self-center">
											<p class="content-c">Carpet Depot Home Center is a furniture store in Claysburg, PA, that offers many options for the various rooms in your home. You can shop from our extensive inventory with the assistance of our professional staff members who are there to help every step of the way.

If you have questions, we have answers and make sure you’re delighted with your final selections.</p>

											<p class="content-c">
                      If you are a resident of Claysburg, PA, Duncansville, PA, Pleasantville, PA, Roaring Spring, PA, or other parts of Blair County, PA, Bedford County, PA, or Cambria County, PA, we’re happy to serve you. Stop by our showroom at your convenience for ideas that will serve you well into the future. We look forward to being the furniture store you turn to again and again for your home.                
											</p>

										</div>
									</div>
								</div>

							</div>
							<br></br>
							<p class="lead">
								<a href="/free-estimate" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: '#2E7D32', borderColor: '#2E7D32', borderRadius: '15px' }}>Request an Estimate</a>
							</p>        </div>

					</div>
				</div>
			</section >
    <div><Footer /></div>
    </div>
  )
}

export default Hardware
