import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import home2 from './Component/s1.png';
import s2 from './Component/s2.png';

function About() {
  return (
    <div>
      <div><Headers/> </div>
      <div className='container'>
      <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>Services</span> <br />
          <small className='justify'> SEE WHY WE’RE THE CLAYSBURG, PA AREA'S MOST TRUSTED SERVICE PROVIDER</small>
        </div>
        <section >
				<div class="row p-3" >
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
						<img src={home2} alt="Los Angeles" class="d-block rounded" />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
						paddingTop: '30px ', paddingLeft: '80px', paddingRight: '80px', paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
              <p class="lead font-bold">Financing <a href='/financing'>Learn more </a></p>
							
							<br></br>
							<p class="lead">
								<a href="/free-estimate" class="btn btn-primary btn-lg active" role="button" style={{ backgroundColor: 'orange', borderColor: 'orange', borderRadius: '15px' }}>Request an Estimate</a>
							</p>        </div>

					</div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-5">
						<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>
					</div>
				</div>
			</section >
      </div>
      <div><Footer/> </div>
    </div>
  )
}

export default About
